import React from 'react';

import ServicesLayout from '../../components/ServicesLayout';
import RetireeBillingImage from '../../images/leave_of_absence.jpg';

const Enrollment = () => (
  <ServicesLayout title="On Leave" bannerTitle="On Leave" bannerCopy="Donec facilisis tincidunt orci non vestibulum. Phasellus pulvinar ante eu vulputate dapibus. Quisque sodales metus at urna dictum consectetur.">
    <h2 className="inner-page__heading">On Leave</h2>
    <p className="inner-page__lead">Convenient Premium Payment Collection for Employees who are On Leave</p>
    <img className="inner-page__hero" src={RetireeBillingImage} alt="Retired man and woman reviewing their retirement finances" />

    <p>Customized billing and administration services for employees who are on leave of absence (LOA), otherwise known as leave without pay (LWOP).</p>

    <p>We provide customized programs that allow employers to outsource leave of absence billing and administration services to Tri-Star, providing a streamlined experience to participants and reducing the employer’s customer service calls.</p>

    <p>Tri-Star Service Highlights:</p>
    <ul>
      <li><strong>Premium Collection & Remittance</strong> for seamless billing and payment of premiums</li>
      <li><strong>Multiple Premium Payment Options</strong> via ACH, Pay Pal or through regular USPS mail</li>
      <li><strong>Monthly Reporting</strong> with easy access to eligibility reports and employee coverage extension  for employers and insurance carriers</li>
      <li><strong>Live Customer Service</strong> for educating and supporting plan beneficiaries</li>
      <li><strong>Eligibility Updates</strong> to insurance carriers through standard HIPAA834 or other required formats</li>
    </ul>
    <p className="inner-page__lead">How can Tri-Star help administer your employee benefits? Call us at <a href="tel:3145764022">314.576.4022</a> or email us at <a href="mailto:info@tri-starsystems.com">info@tri-starsystems.com</a>.</p>
  </ServicesLayout>
);

export default Enrollment
